<template>
    <!-- Page: pages/agency-contacts -->

    <br/>

    <breadcrumbs :items="path"/>

    <div class="container">
        <alert/>
    </div>

    <hr/>

    <div class="container dfw mobile-contain">
        <h1>Agency Contacts</h1>
        <userTools/>
    </div>

    <hr/>

    <div class="container">

        <general-container
            customClass="column"
            :isLocked="false"
        >

            <preloader v-if="!agencies || agencies.length === 0"/>

            <div v-if="!(!agencies || agencies.length === 0)" class="mb-30 browse-contacts-filter-wrapper">
                <p class="card__info__label step-label mb-5">
                    <label>Select an Agency</label>
                </p>
                <div class="mb-30">
                    <custom-select
                        :uniqueId="uniqueId"
                        :items="agencies ? [{ name: 'All Agencies', value: 'all'}].concat(agencies.map(item => ({value: item.locationId, name: item.name})).filter(x => x.name !== 'Legislative Branch' && x.name !== 'Administered Funds')) : [{ name: 'All Agencies', value: 'all'}]"
                        name="agency"
                        placeholder="Select an Agency..."
                        v-model:value="selectedAgencyLocationId"
                    ></custom-select>
                </div>
            </div>
            <div class="browse-contacts-filter-wrapper" v-show="!(!agencies || agencies.length === 0) || selectedAgencyLocationId">
                <search-block
                    type="filter"
                    title="Step 2: Filter Contacts By Name or Position"
                    placeholder="Start typing to narrow results."
                    v-on:search-keyup="searchQuery = $event"
                />
            </div>

            <preloader v-if="loading"></preloader>

            <div class="container view-type-container" v-if="contacts.length > 0">
                <div class="toggle">

                    <span class="toggle__title">Switch View:</span>

                    <span id="view-toggle--table"
                           v-on:click="toggleView()" type="checkbox"
                           :checked="contactView"
                           name="agencies[]"
                           class="view-toggle"
                    >
                        <img src="@/assets/svg/table--inactive.svg"
                            alt="Show Table View"
                            v-if="!contactView"
                            class="mr-10"
                        />
                        <img src="@/assets/svg/table--active.svg"
                            alt="Show Table View"
                            v-else
                            class="mr-10"
                        />
                    </span>

                    <span id="view-toggle--card"
                           v-on:click="toggleView()" type="checkbox"
                           :checked="contactView"
                           name="agencies[]"
                           class="view-toggle"
                    >
                        <img src="@/assets/svg/card--active.svg"
                            alt="Show Table View"
                            v-if="!contactView"
                        />
                        <img src="@/assets/svg/card--inactive.svg"
                            alt="Show Table View"
                            v-else
                        />
                    </span>

                </div>
            </div>
            <div class="container">
                <table class="zebra" v-if="contacts.length > 0 && contactView">
                    <thead>
                    <tr class="tbl-sortable">
                        <th @click="sortField = 'first_name'; sortIsAsc = !sortIsAsc">
                            First name {{ sortField === 'first_name' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                        </th>
                        <th @click="sortField = 'last_name'; sortIsAsc = !sortIsAsc">
                            Last name {{ sortField === 'last_name' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                        </th>
                        <th class="not-sortable">Agency</th>
                        <th @click="sortField = 'salary'; sortIsAsc = !sortIsAsc">
                            Salary {{ sortField === 'salary' ? (sortIsAsc ? '⇩' : '⇧') : '' }}
                        </th>
                        <th class="not-sortable">Position</th>
                        <th class="not-sortable">Program</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="contact in contacts">
                        <td><a :href="contact.url">{{ contact.firstName }}</a></td>
                        <td><a :href="contact.url">{{ contact.lastName }}</a></td>
                        <td>
                            <a v-if="contact.agency && contact.agency.AgencyRef" :href="contact.agency.AgencyRef.url">
                                {{ contact.agency.AgencyRef.name }}
                            </a>
                        </td>
                        <td class="is-family-monospace has-text-right" v-if="contact.salary > 0 ">
                            ${{ priceFormat(contact.salary) }}
                        </td>
                        <td class="is-family-monospace has-text-right" v-else>
                            Not Available
                        </td>
                        <td>
                            <a :href="contact.position.PositionRef.url" v-if="contact.position?.PositionRef">
                                {{ positionName(contact) }}
                            </a>
                        </td>
                        <td>
                            <a v-if="contact.budgets?.length" v-for="budget in contact.budgets"
                               :href="budget.url"
                            >
                                {{ budget.name }}
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="container">
                <div class="columnizer">
                    <contact-information-card
                        v-if="!contactView"
                        v-for="contact in contacts"
                        v-bind="contact"
                        :key="contact.contentId"
                        :contactUrl="contact.url"
                        :agency="contact.agency && contact.agency.AgencyRef ? contact.agency.AgencyRef : null"
                        cardtoolsize="small"
                    />
                </div>
            </div>

            <pagination
                v-if="!loading && !(!agencies || agencies.length === 0) && pageCount > 1"
                @paginated="page = $event"
                :current="page"
                :pageCount="pageCount"
            />

            <div class="error-message" v-if="!loading && !(!agencies || agencies.length === 0) && searchQuery && contacts.length === 0">
                <span>😬 Bummer!</span>
                <p>We couldn't find any results for that.<br/>Are you sure you spelled everything correctly?</p>
            </div>

            <div class="columns mt-30">
                <div class="turnover-chart__container column">
                    <turnover-chart :agency-id="selectedAgencyLocationId"></turnover-chart>
                </div>

                <div class="column">
                    <p class="header-title">Recent Position Changes</p>
                    <preloader v-if="loading"></preloader>
                    <interested-in-block :num="5" :type="'employment_update'"></interested-in-block>
                </div>
            </div>

        </general-container>

    </div>
    <!-- End Page: pages/agency-contacts -->
</template>

<script>
    import RequestHandler from "@/handler/RequestHandler";
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "@/alivue/components/breadcrumbs";
    import GeneralContainer from "./general-container";
    import InterestedInBlock from '@/alivue/components/interested-in-block.vue';
    import UserTools from "@/alivue/components/user-tools";
    import ContactInformationCard from "./contact-info-card";
    import SearchBlock from "./search-block";
    import { computed, ref, watch, onMounted } from "vue";
    import { useStore } from "vuex";
    import Preloader from "./preloader";
    import CustomSelect from "./custom-select";
    import Pagination from "./pagination";
    import { priceFormat } from "@/helpers/filter";
    import TurnoverChart from "./turnover-chart";

    export default {
        components: {
            Pagination,
            Alert,
            Breadcrumbs,
            GeneralContainer,
            UserTools,
            ContactInformationCard,
            SearchBlock,
            Preloader,
            CustomSelect,
            InterestedInBlock,
            TurnoverChart
        },
        data() {
            return {
                contacts: [],
                selectedAgencyLocationId: '',
                page: 1,
                pageCount: 1,
                pageSize: 24,
                loading: true,
                sortField: '',
                sortIsAsc: true,
                searchQuery: '',
                typingTimer: null,
            };
        },
        mounted() {
            if (this.$route.query.agencyId) {
                this.selectedAgencyLocationId = this.$route.query.agencyId;
            } else {
                this.loading = false;
            }
        },
        computed: {
            agencies: function () {
                return this.$store.getters.agencies;
            },
            selectedAgency: function () {
                if (!this.selectedAgencyLocationId || !this.agencies) {
                    return null;
                }
                for (let i = 0; i < this.agencies.length; i++) {
                    if (this.agencies[i].locationId == this.selectedAgencyLocationId) {
                        return this.agencies[i];
                    }
                }

                return null;
            }
        },
        methods: {
            loadContacts() {
                this.loading = true;
                this.contacts = [];
                let searchQuery = this.searchQuery;
                let agencyId = this.selectedAgencyLocationId;
                if (agencyId === 'all') {
                    agencyId = '';
                }
                RequestHandler.loadFullContacts(
                    agencyId, (this.page - 1) * this.pageSize, this.pageSize,
                    this.sortField, this.sortIsAsc ? 'asc' : 'desc', this.sortField === '',
                    this.searchQuery
                ).then(response => {
                    if (searchQuery !== this.searchQuery) {
                        return;
                    }
                    this.contacts = response.ContentList.list;
                    this.pageCount = response.ContentList.pageCount;
                    this.loading = false;
                }).catch(error => {
                    console.error(error);
                    this.loading = false;
                    this.pageCount = 1;
                });
            },
            toggleView(){
                this.contactView = !this.contactView;
            },
            positionName(contact) {
                let name = contact.position.PositionRef && contact.position.PositionRef.name || contact.position.title;
                if (contact.positionRole) {
                    name += ' (' + contact.positionRole + ')';
                }

                return name;
            }
        },
        watch: {
            selectedAgencyLocationId() {
                this.loadContacts();
                this.$router.push({ name: 'agency-contacts', query: { 'agencyId': this.selectedAgencyLocationId } });
                this.searchQuery = null;
            },
            searchQuery() {
                this.page = 1;
                this.pageCount = 1;
                if (this.typingTimer) {
                    clearTimeout(this.typingTimer);
                }
                this.typingTimer = setTimeout(this.loadContacts, 200);
            },
            page() {
                window.scrollTo(0, 0);
                this.loadContacts();
            },
            sortIsAsc() {
                this.loadContacts();
            }
        },
        props: ['uniqueId'],
        setup(props) {
            const store = useStore();
            const contactView = ref(false);
            const showSearch = computed(() => store.getters.showSearch);

            return {
                path: [{ title: 'Agency Contacts' }],
                priceFormat,
                showSearch,
                contactView
            };
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

    .header-title {
        font-weight: 600;
        font-family: Fira Sans,sans-serif;
        font-size: 28px;
        margin-bottom: .5em;
        color: #022c43;
    }

    .error-message {
        width: 100%;
        text-align: center;
        font-size: 1.25em;

        span {
            font-size: 1.5em;
            font-weight: 600;
        }
    }

    .browse-contacts-filter-wrapper {
        max-width: 50%;
        margin: auto;
        margin-top: -12px;
        @media screen and (max-width: 768px) {
            width: 90%;
            max-width: 90%;
        }

        .search-block {
            margin-bottom: 30px;
        }

        .card__info__label {
            label {
                font-size: 28px;
                font-weight: 600;
                font-family: $header;
                text-transform: capitalize;
                margin-bottom: 5px;
            }
        }
    }

    .step-label {
        font-size: 1.75em;
    }

    .mb-30 {
        margin-bottom: 30px;
    }

    .mt-30 {
        margin-top: 30px;
    }

    .mr-10 {
        margin-right: 10px;
    }

    .custom-select {
        width: 100%;
    }

    .mobile-contain {
        h1 {
            float: left;
        }

        @media screen and (max-width: 1021px) {
            margin-left: 15px;
            margin-right: 15px;
            h1 {
                text-align: center;
                float: none;
            }
        }
    }

    table {
        margin: auto;
        margin-bottom: 30px;
        display: inline-table;

        th {
            color: $white;
            background: $blue--dark;
            background: $blue-gradient;
        }

        td, th {
            padding: 10px;
            border: solid 1px $grey--dark;
        }

        &.zebra {
            width: 100%;
            background-color: $white;
        }
    }

    .zebra tr:nth-child(even) {
        //background: #eee;
    }

    .zebra tr:nth-child(odd) {
        background-color: $white;
    }

    .tbl-sortable th {
        cursor: pointer;

        &.not-sortable {
            cursor: default;
        }
    }
    .toggle {
        margin-left: 15px;
    }
    .view-type-container {
        margin-bottom: 30px;
        text-align: right;
    }
    .view-toggle img {
        max-width: 24px;
    }
    .toggle__title {
        margin-right: 10px;
    }

    .column {
        @media screen and (max-width: 768px) {
            flex-basis: initial;
        }
    }
</style>
